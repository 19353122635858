import { DefaultTheme } from 'styled-components'
import base from './base'
import { colors } from './colors'

const theme: DefaultTheme = {
  colors,
  ...base,
}

export default theme
