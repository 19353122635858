import ArabFlagIcon from 'svgs/flag/arab.svg'
import BrazilFlagIcon from 'svgs/flag/brazil.svg'
import ChinaFlagIcon from 'svgs/flag/china.svg'
import EnglishFlagIcon from 'svgs/flag/english.svg'
import FranceFlagIcon from 'svgs/flag/france.svg'
import HongkongFlagIcon from 'svgs/flag/hongkong.svg'
import IndoFlagIcon from 'svgs/flag/indo.svg'
import KoreaFlagIcon from 'svgs/flag/korea.svg'
import PortugalFlagIcon from 'svgs/flag/portugal.svg'
import SpainFlagIcon from 'svgs/flag/spain.svg'
import ThailandFlagIcon from 'svgs/flag/thailand.svg'
import TurkeyFlagIcon from 'svgs/flag/turkey.svg'
import VietnameseFlagIcon from 'svgs/flag/vietnamese.svg'
import RussianFlagIcon from 'svgs/flag/russia.svg'
import ItalianFlagIcon from 'svgs/flag/italian.svg'
import JapaneseFlagIcon from 'svgs/flag/japan.svg'
import ArrowRightAlt from 'svgs/arrow-right-alt.svg'

import CasinoGameIcon from 'svgs/casino.svg'
import SportGameIcon from 'svgs/sport.svg'
import GameIcon from 'svgs/all-game-icon.svg'
import SuccessStatus from 'svgs/success-status.svg'
import CouponIcon from 'svgs/coupon.svg'
import LikeIcon from 'svgs/like.svg'
import LikeAltIcon from 'svgs/like-alt.svg'
import UnlikeIcon from 'svgs/unlike.svg'
import ShareArrowIcon from 'svgs/arrow-up-alt.svg'

import MenuIcon from 'svgs/menu.svg'
import MenuBarIcon from 'svgs/menu-bar.svg'
import UserIcon from 'svgs/user-icon.svg'
import OutlinedUserIcon from 'svgs/user-icon-outlined.svg'
import BlockIcon from 'svgs/block-icon.svg'

import HunnyPokerIcon from 'svgs/hunnypoker.svg'
import TransactionHistoryIcon from 'svgs/transaction-history.svg'
import HunnyFarmIcon from 'svgs/farm.svg'
import HunnySwapIcon from 'svgs/hunnyswap.svg'
import ExpandIcon from 'svgs/expand.svg'
import StakeIcon from 'svgs/stake.svg'
import NewsIcon from 'svgs/news.svg'
import NewIcon from 'svgs/new.svg'
import NewSecondary from 'svgs/new-secondary.svg'
import ComingSecondary from 'svgs/coming-secondary.svg'
import ReferralIcon from 'svgs/referral.svg'
import JarIcon from 'svgs/jar.svg'
import ArrowUpIcon from 'svgs/arrow-up.svg'
import ArrowDownIcon from 'svgs/arrow-down.svg'
import ArrowDownIconAlt from 'svgs/arrow-down-alt.svg'
import ArrowRight from 'svgs/arrow-right.svg'
import CopyRightIcon from 'svgs/copy-right.svg'
import CopyIcon from 'svgs/copy-icon.svg'
import CopyIconOutline from 'svgs/copy-icon-outline.svg'
import EmailIcon from 'svgs/email-default.svg'
import CloseCircleIcon from 'svgs/close-circle.svg'
import CloseStrokeIcon from 'svgs/close-stroke.svg'
import CloseIcon from 'svgs/close.svg'
import FullScreenIcon from 'svgs/fullscreen.svg'
import PlayIcon from 'svgs/play.svg'
import PlayAltIcon from 'svgs/play-alt.svg'
import BonusIcon from 'svgs/bonus.svg'

import SearchIcon from 'svgs/search.svg'
import ChevronLeftIcon from 'svgs/chevron-left.svg'
import ChevronRightIcon from 'svgs/chevron-right.svg'
import ClearIcon from 'svgs/clear.svg'
import BackIcon from 'svgs/back.svg'
import HomeIcon from 'svgs/home-outline.svg'
import LoginIcon from 'svgs/login.svg'
import SupportIcon from 'svgs/support-outline.svg'
import HusdIcon from 'svgs/hpd-navigation-icon.svg'
import CashbackIcon from 'svgs/cashback.svg'
import LogoutIcon from 'svgs/logout.svg'
import EyeIcon from 'svgs/eye.svg'
import EyeRevealIcon from 'svgs/eye_reveal.svg'
import PasswordIcon from 'svgs/password.svg'
import WarningIcon from 'svgs/warning.svg'
import InfoIcon from 'svgs/info.svg'
import InfoCircleIcon from 'svgs/info-circle.svg'
import SuccessIcon from 'svgs/success.svg'
import WalletIcon from 'svgs/vuesax/empty-wallet-add.svg'
import OpenPageicon from 'svgs/open-page-icon.svg'
import HourGlassIcon from 'svgs/hourglass.svg'
import SuccessCheckIcon from 'svgs/success-check.svg'
import AddIconOutlined from 'svgs/add-icon.svg'
import EditIcon from 'svgs/edit-icon.svg'
import DateRangeIcon from 'svgs/date-range-icon.svg'
import TrashbinIcon from 'svgs/trash-bin-icon.svg'
import VerifyIcon from 'svgs/verify.svg'
import BinIcon from 'svgs/bin.svg'
import ProviderFilterIcon from 'svgs/provider-filter.svg'
import NotificationIcon from 'svgs/noti-icon.svg'
import HelpOutlineIcon from 'svgs/help-outline.svg'
import TripleArrowIcon from 'svgs/triple-arrow.svg'
import DashboardIcon from 'svgs/dashboard.svg'
import CupIcon from 'svgs/cup.svg'
import GiftIcon from 'svgs/gift.svg'
import ProfileIcon from 'svgs/profile.svg'

import CircleLoadingBackgroundIcon from 'svgs/circle-loading-background.svg'
import CircleLoadingIcon from 'svgs/circle-loading.svg'

import TelegramIcon from 'svgs/telegram.svg'
import TwitterIcon from 'svgs/twitter.svg'
import MediumIcon from 'svgs/medium.svg'
import DiscordIcon from 'svgs/discord.svg'

import WithdrawIcon from 'svgs/vuesax/card-send.svg'
import SwapIcon from 'svgs/icon-swap.svg'

import MetamaskIcon from 'svgs/wallet/metamask-fox.svg'
import BitKeepIcon from 'svgs/wallet/bitkeep.svg'
import Coin98 from 'svgs/wallet/coin98.svg'
import MathWallet from 'svgs/wallet/math-wallet.svg'
import SafePal from 'svgs/wallet/safepal.svg'
import TokenPocket from 'svgs/wallet/token-pocket-wallet.svg'
import TrustWallet from 'svgs/wallet/trust-wallet.svg'
import BSCWallet from 'svgs/wallet/wallet-bsc.svg'
import WalletConnect from 'svgs/wallet/wallet-connect.svg'
import PhantomWallet from 'svgs/wallet/phantom.svg'
import SlopeWallet from 'svgs/wallet/slope.svg'
import SolletWallet from 'svgs/wallet/sollet.svg'
import CloverWallet from 'svgs/wallet/clover.svg'
import SolflareWallet from 'svgs/wallet/solflare.svg'
import LiquidBottleIcon from 'svgs/liquid-bottle-icon.svg'

export const Icons = {
  MenuIcon,
  MenuBarIcon,
  HunnyPokerIcon,
  HunnyFarmIcon,
  JarIcon,
  HunnySwapIcon,
  TransactionHistoryIcon,
  ExpandIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  ArrowDownIconAlt,
  ArrowRight,
  ArrowRightAlt,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClearIcon,
  StakeIcon,
  LikeIcon,
  LikeAltIcon,
  UnlikeIcon,
  NewIcon,
  NewsIcon,
  NewSecondary,
  ComingSecondary,
  ReferralIcon,
  CopyRightIcon,
  CopyIcon,
  CopyIconOutline,
  InfoCircleIcon,
  EmailIcon,
  CloseCircleIcon,
  CloseStrokeIcon,
  CloseIcon,
  FullScreenIcon,
  UserIcon,
  OutlinedUserIcon,
  AddIconOutlined,
  EditIcon,
  TelegramIcon,
  TwitterIcon,
  MediumIcon,
  DiscordIcon,
  SearchIcon,
  BackIcon,
  HomeIcon,
  OpenPageicon,
  LoginIcon,
  SupportIcon,
  EyeIcon,
  EyeRevealIcon,
  PasswordIcon,
  WarningIcon,
  WalletIcon,
  DateRangeIcon,
  TrashbinIcon,
  CouponIcon,
  CircleLoadingBackgroundIcon,
  CircleLoadingIcon,
  InfoIcon,
  SuccessIcon,
  HourGlassIcon,
  SuccessCheckIcon,
  VerifyIcon,
  ProviderFilterIcon,
  PlayIcon,
  PlayAltIcon,
  HelpOutlineIcon,
  TripleArrowIcon,
  DashboardIcon,
  GiftIcon,
  CupIcon,
  ProfileIcon,
  BonusIcon,
  ShareArrowIcon,

  // navigation icons
  CashbackIcon,
  HusdIcon,
  LogoutIcon,
  NotificationIcon,

  /// payment
  WithdrawIcon,
  SwapIcon,

  // wallet icons
  Coin98,
  BitKeepIcon,
  MetamaskIcon,
  MathWallet,
  SafePal,
  TokenPocket,
  TrustWallet,
  BSCWallet,
  WalletConnect,
  PhantomWallet,
  SlopeWallet,
  SolletWallet,
  CloverWallet,
  SolflareWallet,

  CasinoGameIcon,
  SportGameIcon,
  GameIcon,
  BinIcon,
  BlockIcon,

  // status
  SuccessStatus,

  // flags
  ArabFlagIcon,
  BrazilFlagIcon,
  ChinaFlagIcon,
  EnglishFlagIcon,
  FranceFlagIcon,
  HongkongFlagIcon,
  IndoFlagIcon,
  KoreaFlagIcon,
  PortugalFlagIcon,
  SpainFlagIcon,
  ThailandFlagIcon,
  TurkeyFlagIcon,
  VietnameseFlagIcon,
  JapaneseFlagIcon,
  LiquidBottleIcon,
  RussianFlagIcon,
  ItalianFlagIcon,
}

export const getIcon = (iconName: keyof typeof Icons | string) => {
  return Icons[iconName]
}

