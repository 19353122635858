import { MediaQueries, Breakpoints, Spacing } from './types'

export const breakpointMap: { [key: string]: number } = {
  xs: 370,
  sm: 576,
  md: 852,
  lg: 968,
  xl: 1250,
  xxl: 1400,
  xxxl: 1600,
}

export const breakpointNumberList = Object.values(breakpointMap)

const breakpoints: Breakpoints = Object.values(breakpointMap).map((breakpoint) => `${breakpoint}px`)

const mediaQueries: MediaQueries = {
  xs: `@media screen and (min-width: ${breakpointMap.xs}px)`,
  sm: `@media screen and (min-width: ${breakpointMap.sm}px)`,
  md: `@media screen and (min-width: ${breakpointMap.md}px)`,
  lg: `@media screen and (min-width: ${breakpointMap.lg}px)`,
  xl: `@media screen and (min-width: ${breakpointMap.xl}px)`,
  xxl: `@media screen and (min-width: ${breakpointMap.xxl}px)`,
  xxxl: `@media screen and (min-width: ${breakpointMap.xxxl}px)`,
}

export const transitions = {
  fast: 'all 0.2s',
  medium: 'all 0.5s',
}

const spacing: Spacing = [0, 4, 8, 16, 24, 32, 48, 64]

const radii = {
  tiny: '4px',
  small: '8px',
  default: '10px',
  large: '15px',
  extraLarge: '20px',
  modal: '16px',
}

const zIndices = {
  menu: 5,
  dropdown: 10,
  modal: 100,
}

export default {
  siteWidth: 1328,
  topbarHeight: '65px',
  bottombarHeight: '64px',
  navigationWidth: 246,
  navigationSmallWidth: 65,
  sidebarWidth: 320,
  breakpoints,
  mediaQueries,
  spacing,
  radii,
  transitions,
  zIndices,
}
